function getSideBarNavItems() {
  var permittedUrls = [];
  var role = JSON.parse(sessionStorage.getItem("role"));
  console.log("Role from session : ", role);

  if (role) {
    const roles = role.split(",");
    if (roles && roles.length > 0) {
      console.log("roles.indexOf : ", roles.indexOf("Admin"));
      if (roles.indexOf("Admin") !== -1) {
        console.log("Role from session : ", role);
        // permittedUrls.push(populateSideBarNavItems("home"));
        permittedUrls.push(populateSideBarNavItems("bed"));
        //permittedUrls.push(populateSideBarNavItems("form"));
        permittedUrls.push(populateSideBarNavItems("doctor"));
        permittedUrls.push(populateSideBarNavItems("service"));
        permittedUrls.push(populateSideBarNavItems("Patient"));
      } else if (roles.indexOf("doctor") !== -1) {
        permittedUrls.push(populateSideBarNavItems("doctorHome"));
      }
      else if (roles.indexOf("serviceDesk") !== -1) {
        permittedUrls.push(populateSideBarNavItems("home"));

      }
    }
  }
  console.log("permitted urls : ", permittedUrls);
  return permittedUrls;
}

function populateSideBarNavItems(type) {
  var obj = {};

  switch (type) {
    case "home":
      obj["title"] = "Patients";
      obj["htmlBefore"] = '<i class="material-icons">home</i>';
      obj["to"] = "/Home";
      obj["htmlAfter"] = "";
      break;

    case "bed":
      obj["title"] = "Bed";
      obj["htmlBefore"] = '<i class="material-icons">bed</i>';
      obj["to"] = "/Bed";
      // obj['htmlAfter'] = "";
      break;

    case "form":
      obj["title"] = "Consent Form";
      obj["htmlBefore"] = '<i class="material-icons">vertical_split</i>';
      obj["to"] = "/Forms";
      break;

    case "doctor":
      obj["title"] = "Setup Doctor";
      obj["htmlBefore"] = '<i class="material-icons">note_add</i>';
      obj["to"] = "/ListDoctor";
      break;

    case "Patient":
      obj["title"] = "Patient List";
      obj["htmlBefore"] = '<i class="material-icons">note_add</i>';
      obj["to"] = "/Patient";
      break;

    case "service":
      obj["title"] = "My Services";
      obj["htmlBefore"] = '<i class="material-icons">view_module</i>';
      obj["to"] = "/ServiceMaster";
      break;

    case "doctorHome":
      obj["title"] = "Home";
      obj["htmlBefore"] = '<i class="material-icons">home</i>';
      obj["to"] = "/Dashboard";
      obj["htmlAfter"] = "";
      break;
    default:
      break;
  }
  return obj;
}

const helperServices = {
  getSideBarNavItems,
  populateSideBarNavItems,
};
export default helperServices;
