import React, { useState, useEffect, Component } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { loginActions } from "../../core/actions";
import { patientActions } from "../../core/actions";
import { helperServices } from "../../data/index";
import {
  Grid,
  Button,
  Box,
  TextField,
  Paper,
  Typography,
  Dialog
} from "@mui/material";
import { LOGIN_REQUEST, SET_SIGNINDATA } from "../../shared/constants";


function Login(props) {
  const [showForm, setShowForm] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [user, setUser] = useState({
    loginId: "",
    loginPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState("");
  const [responseCodeMsgHashMap, setResponseCodeMsgHashMap] = useState([]);
  const [sidebarNavItems, setSidebarNavItems] = useState(
    helperServices.getSideBarNavItems()
  );
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [login, setlogin] = useState(true);
  const [logout, setLogout] = useState(false);
  // const [url]=useState({'http://localhost:6081/api/auth/accounts/v1/signin'});
  const [error, setError] = useState(false);
  // const [url]=useState({'http://localhost:8081/api/auth/accounts/v1/signin'});
  // let url = "http://localhost:6081/api/auth/accounts/v1/googlelogin";
  // const clientId =
  //   "280018234179-qnlgdnsrhjeg7fd5jlggardtbg6ccfil.apps.googleusercontent.com";
  // let loginurl = "http://localhost:6081/api/auth/login";
  let url = process.env.REACT_APP_ACCOUNTS_URL;
  const clientId = "280018234179-qnlgdnsrhjeg7fd5jlggardtbg6ccfil.apps.googleusercontent.com";
  // let loginurl = "http://localhost:7081/api/auth/login";      
  let loginurl = process.env.REACT_APP_ACCOUNTS_LOGINURL;
  var userData = user.loginId;

  const handleSubmit = (event) => {
    console.log("In handle submit of login page");
    let hidbtnid = document.getElementById("loginbutton");
    //  setSubmitted(true);
    //  Object.keys(user).forEach((key) => {
    //    validateField(key, user[key]);
    //  });

    //  validateForm(event);
  };

  const handlegoogleSubmit = (event) => {
    let googleform = document.getElementById("formid");
    console.log("documents:", googleform);
    googleform.submit();
  };

  function handleCallbackResponse(response) {
    console.log("Encoded jwt token: " + response.credential);
    var userobject = jwt_decode(response.credential);
    setToken(response.credential);
    setEmail(userobject.email);
    console.log(
      "userobject :",
      userobject,
      "userobject.email :",
      userobject.email
    );
    console.log(document);
    handlegoogleSubmit(response);
  }

  useEffect(() => {
    /* global google */
    if (google.accounts) {
      google.accounts.id.initialize({
        client_id:
          "465066940286-38oal5huee33jf7dlnolicdgj33jsllc.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("google"), {
        theme: "outline",
        size: "large",
      });
    }

    const url = window.location.href;
    console.log("url : ", url);
    let arr = [];
    if (url.includes("error")) {
      arr = url.split("=");
      if (arr[1] === "0") {
        setError(true);
      }
    }
    console.log("arr : ", arr);
    // console.log("log ")
    // const script = document.createElement('script');
  

    // script.src = 'https://accounts.google.com/gsi/client';
    // script.async = true;
   
    // document.body.appendChild(script);
    // script.addEventListener ('load',() => {
    //   console.log('page is fully loaded');
      
    //   window.google.accounts.id.initialize({
    //     client_id: "280018234179-1dhmfru6nkiurcnobetbumgvgdjji8l3.apps.googleusercontent.com",
    //     callback: handleCredentialResponse()
    //   });
    //   window.google.accounts.id.renderButton(
    //     document.getElementById("mybutton"),
    //     { theme: "outline", size: "large" }  // customization attributes
    //   );
    //   window.google.accounts.id.prompt();
    // });

    
   
   
  }, []);
  

  return (
    <div className="welcome wel1 d-flex justify-content-center">
      <Grid
        container
        component="main" 
        className="h-100"
        sx={{ background: "url('bg-6.png')" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="d-flex align-items-center justify-content-center"
          sx={{}}
        >
          <img src="doct_edit.png" className="img-1" alt="" />
          <Grid
            container
            spacing={2}
            className="h-80 p-2"
            sx={{
              width: "80%",
              borderRadius: "30px",
              background: "rgb(199,181,255);",
              background:
                "linear-gradient(171deg, rgba(199,181,255,1) 0%, rgba(108,29,169,1) 50%, rgba(13,8,72,1) 100%);",
            }}
          >
            {/*
             */}
            <Grid
              item
              xs={12}
              md={7}
              sm={6}
              className="d-flex align-items-center"
              sx={{}}
            >
              <div className="">
                <div>
                  <img src="stethoscope.png" className="img-3"></img>
                </div>
                <div className="text">Welcome.</div>
                <div className="container">
                  <div className="typewriter pb-2">
                    Enter your username and password.
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sm={6}
              className="pl-0 pt-0"
              sx={{ background: "white", borderRadius: "30px" }}
            >
              <Grid
                fullWidth
                className="d-flex flex-column align-items-center justify-content-center login-grid"
              >
                <div>
                  <img
                    src="Manaswini_Logo.jpg"
                    className="manaswini-logo w-100"
                  ></img>
                </div>
                <Typography variant="h5" component="h5" className="p-2">
                  Login
                </Typography>

                <Grid item container className="x_content d-flex" xs={12}>
                  <form
                    className="w-100"
                    id="loginformid"
                    action={loginurl}
                    method="get"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Grid item xs={12} className="p-2 mx-4 mx-md-5">
                      <TextField
                        fullWidth
                        autoFocus
                        htmlFor="uname"
                        label="Username"
                        name="userName"
                        required
                        error={error}
                      />
                    </Grid>

                    <Grid item xs={12} className="p-2 mx-4 mx-md-5">
                      <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        required
                        error={error}
                      />
                    </Grid>

                    <Grid item xs={12} className="p-2 mx-4 mx-md-5">
                      <Button
                        id="loginbutton"
                        variant="contained"
                        color="primary"
                        type="submit"
                        error={error}
                        fullWidth
                        // onClick={(event) => handleSubmit(event)}
                      >
                        Login
                      </Button>
                    </Grid>

                    <Grid item xs={12} className="p-2 mx-4 mx-md-5">
                    <form
                          className=""
                          id="formid"
                          method="get"
                          action={url}
                        >
                      <div id="google"></div>

                      <div>
                        <input
                              type="text"
                              // value={email}
                              placeholder="Email"
                              value={email}
                              name="email"
                              hidden
                              // onChange={(e) => setEmail(e.target.value)}
                            />
                             <input
                              type="text"
                              value={token}
                              name="token"
                              hidden
                            />
                        </div>
                    </form>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}


export default Login;
