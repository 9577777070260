export const PATIENT_DETAILS = 'PATIENT_DETAILS';
export const PATIENT_SEARCH_FAILURE = 'PATIENT_SEARCH_FAILURE';
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const PATIENT_SAVE_FAILURE = 'PATIENT_SAVE_FAILURE';
export const PATIENT_SAVE_SUCCESS = 'PATIENT_SAVE_SUCCESS';
export const PATIENT_UPDATE_FAILURE = 'PATIENT_UPDATE_FAILURE';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_VISIT_SAVE_SUCCESS = 'PATIENT_VISIT_SAVE_SUCCESS';
export const PATIENT_VISIT_SAVE_FAILURE = 'PATIENT_VISIT_SAVE_FAILURE';
export const VISIT_DETAILS = 'VISIT_DETAILS';
export const VISIT_DETAIL_FAILURE = 'VISIT_DETAIL_FAILURE';
export const PROCEDURE_SAVE_SUCCESS = 'PROCEDURE_SAVE_SUCCESS';
export const PROCEDURE_SAVE_FAILURE = 'PROCEDURE_SAVE_FAILURE';
export const SET_PROCEDURE_ID = 'SET_PROCEDURE_ID';
export const SET_VISIT_ID = 'SET_VISIT_ID';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const COMPLAINT_SAVE_SUCCESS = 'COMPLAINT_SAVE_SUCCESS';
export const COMPLAINT_SAVE_FAILURE = 'COMPLAINT_SAVE_FAILURE';
export const REFERENCE_SAVE_SUCCESS = 'REFERENCE_SAVE_SUCCESS';
export const REFERENCE_SAVE_FAILURE = 'REFERENCE_SAVE_FAILURE';

export const BED_DETAILS = 'BED_DETAILS';
export const BED_SEARCH_FAILURE = 'BED_SEARCH_FAILURE';
export const BED_SAVE_SUCCESS = 'BED_SAVE_SUCCESS';
export const BED_SAVE_FAILURE = 'BED_SAVE_FAILURE';
export const BED_UPDATE_SUCCESS = 'BED_UPDATE_SUCCESS';
export const BED_UPDATE_FAILURE = 'BED_UPDATE_FAILURE';

export const SERVICE_SAVE_FAILURE = 'SERVICE_SAVE_FAILURE';
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS';
export const CATEGORY_DETAILS = 'CATEGORY_DETAILS';

export const SET_DOCTOR_DETAILS = 'SET_DOCTOR_DETAILS';
export const DEPT_DOCTOR_DETAILS = 'DEPT_DOCTOR_DETAILS';
export const DEPT_DOCTOR_DETAILS_FAILURE = 'DEPT_DOCTOR_DETAILS_FAILURE';
export const DOCTOR_SAVE_SUCCESS = 'DOCTOR_SAVE_SUCCESS';
export const DOCTOR_SAVE_FAILURE = 'DOCTOR_SAVE_FAILURE';
export const DOCTOR_UPDATE_SUCCESS = 'DOCTOR_UPDATE_SUCCESS';
export const DOCTOR_UPDATE_FAILURE = 'DOCTOR_UPDATE_FAILURE';

export const DIAGNOSIS_SAVE_FAILURE = 'DIAGNOSIS_SAVE_FAILURE';
export const DIAGNOSIS_SAVE_SUCCESS = 'DIAGNOSIS_SAVE_SUCCESS';
export const DIAGNOSIS_MASTER_SAVE_FAILURE = 'DIAGNOSIS_MASTER_SAVE_FAILURE';
export const DIAGNOSIS_MASTER_SAVE_SUCCESS = 'DIAGNOSIS_MASTER_SAVE_SUCCESS';
export const DIAGNOSIS_DETAILS_FAILURE = 'DIAGNOSIS_DETAILS_FAILURE';
export const DIAGNOSIS_DETAILS = 'DIAGNOSIS_DETAILS';
export const SET_DIAGNOSIS_DETAILS = 'SET_DIAGNOSIS_DETAILS';
export const DIAGNOSIS_MASTER_DETAILS_FAILURE = 'DIAGNOSIS_MASTER_DETAILS_FAILURE';
export const DIAGNOSIS_MASTER_DETAILS = 'DIAGNOSIS_MASTER_DETAILS';

export const FORM_DETAILS = 'FORM_DETAILS';
export const DOC_CATEGORIES = [{id:1, value:'Full Time'},{id:2, value:'Part Time'}];
export const LOGIN_REQUEST = 'LOGIN_REQUEST';


export const LIST_PATIENTS = 'LIST_PATIENTS';
export const LIST_PATIENTS_FAILURE = 'LIST_PATIENTS_FAILURE';
export const PATIENTS_INFO = 'PATIENTS_INFO';
export const PATIENTS_INFO_FAILURE = 'PATIENTS_INFO_FAILURE';
export const LIST_FLOORS = 'LIST_FLOORS';
export const PATIENTS_PROCEDURE_INFO = "PATIENTS_PROCEDURE_INFO";

export const FLOORNURSE = 'floornurse'
export const PATIENTS_LAST_VISITED_INFO = 'PATIENTS_LAST_VISITED_INFO'
export const SET_UPLOAD = 'SET_UPLOAD'
export const SET_SIGNINDATA = 'SET_SIGNINDATA'

