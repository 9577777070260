import React from "react";
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Components from "./components/index";
import { DefaultLayout } from "./layouts/index";
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/css/bootstrap.min.css";
// import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

class App extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Routes>
       {/* {  <Route
          path="/"
          children={(routeProps) =>
            routeProps.match.isExact ? <Navigate to="/accountslogin" /> : ""
          }
        /> } */}
        {/* <Route
  path="/"
  // element={
  //   (routeProps) => {
  //     return routeProps.match.isExact ? <Navigate to="/accountslogin" /> : null;
  //   }
  // }
  element={<Components.Login/>}
/> */}

<Route
  path="/"
  // element={
  //   (routeProps) => {
  //     return routeProps.match.isExact ? <Navigate to="/accountslogin" /> : null;
  //   }
  // }
  element={<Navigate to="/accountslogin" replace />}
  // element={<Components.Login/>}
/>

        <Route path={"/accountslogin"} element={<Components.Login/>} />
        <Route path={"/form"} element={<MyLayout/>} />
    
        <Route exact path="/FormDetail/:formId" element={<Components.GoogleSignIn/>} />
        </Routes>
        
      </div>
    );
  }
}

class MyLayout extends React.Component {
  render() {
    return (
      <>
      {/* <Route path={"/form"} component={Components.GoogleForm} /> */}
       <Routes>
       
      <Route path={"/Login"} component={Components.Login} />
      <Route path={"/googlesignin"} component={Components.GoogleSignIn} />
      
      <DefaultLayout>

        <Route
          exact
          path="/DiagnosisMaster/:patientId"
          render={(props) => <Components.DiagnosisMaster {...props} />}
        />
       
      </DefaultLayout>
      </Routes>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

App.propTypes = {};

const AppWithProps = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppWithProps;
